import {
  
  GetWave,
  updateUserSelectedProjects,
  getProjectIds,
  updateWaveCurrentStatus,
  UnlockWaveModuleSelection,
  sendNotification,
} from '../../../store/actions';

import ModalDialog from '../../ui/modal/Modal';
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from './WaveStatus.module.css';
import Button from '../../ui/buttons/Button';
import AccordionDisplay from '../../ui/nav/AccordionDisplay';
import { useNavigate } from 'react-router-dom';
import MultiSelectDropdown from '../../ui/MultiSelectDropdown/MultiSelectDropdown';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Dropdown from '../../ui/Dropdown/Dropdown';

import { checkUXPrivileges, getURLOrigin} from '../../../utility/utility.js';
import RequestModuleSelectionUnlock from '../../ui/popupContents/RequestModuleSelectionUnlock/RequestModuleSelectionUnlock.js';
/**
 * @class WaveStatus
 * @param {Array} countries Array of all countries the user has access to. Each item in the array represents a country and contains the following keys: 'countryId', 'name', 'projectStatusForCountrySpecificData', 'userCountrys', and 'waves'.
 * @param {Number} currentWaveId
 * @param {Function} statusToStatusType @see '../../pages/waveSelection/WaveSelection', lines 61-73
 * @param {Boolean} editWavesPrivilege Boolean for if the user has 'editWave' as one of their ux privileges
 * @param {Number} scrollTo Id of the country that will be scrolled into view. This is taken from the URL params when the component mounts, and then is updated to the corresponding countryId value if the user clicks on a country in the 'CountryMenu' component.
 *
 * @description This component renders all countries that the user has access to, each country containing their corresponding waves. It contains functions that will be used in child components to either view/preview the wave's module selection, or edit the wave, as well as allow users to create new waves for the countries they have access to by clicking on the 'Add Wave' button.
 *
 * When the component is rendered, and if a country is available in the URL params the country will be scrolled into view. The component also scrolls the appropriate country into view if one is clicked on in the 'CountryMenu' component.
 *
 * The component also allows users to filter the projects they want to see by their type and year using a dropdown available at the top of the component.
 */
const WaveStatus = ({
  countries,
  currentWaveId,
  statusToStatusType,
  editWavesPrivilege,
  scrollTo,
}) => {
  const ref = useRef({});
  const navigate = useNavigate();
  const wave = useSelector((state) => state.wave.wave);
  const [showModalSelectionFinishedModal, setShowModalSelectionFinishedModal] =
    useState(false);
  const [countryInfo, setCountryInfo] = useState(null); // required for unlocking Module Selection using the modal
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.app.projects);
  const userSelectedProjects = useSelector(
    (state) => state.app.userSelectedProjects
  );
  const [projectList, setProjectList] = useState([]);
  const messages = useSelector((state) => state.message.messages);

  const [showDownloadModulesModal, setShowDownloadModulesModal] =
    useState(false);
  const [projectForDownload, setProjectForDownload] = useState();
  const countryForDownload = useRef();
  const [projectsForDownload, setProjectsForDownload] = useState([]);
  const projectModulesForDownload = useSelector(
    (state) => state.modules.projectModules
  );
  const [downloadError, setDownloadError] = useState({});
  const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
  const [showRequestSelectionUnlockModal, setShowRequestSelectionUnlockModal] =
    useState({ show: false, countryId: -1, waveId: -1 });
  const { notifications } = useSelector((store) => store.notifications);
  /**
   * @function
   * @memberof WaveStatus
   * @param {Array} projects Array of all project types available for the project.
   *
   * @description The function takes an array 'projects' as argument, combines it and 'userSelectedProjects' into a new array, removes duplicates, and then dispatches 'updateUserSelectedProjects' with the array it processed. The function is used to update the 'userSelectedProjects' array, which filters  which project types are rendered. After doing so it dispatches 'updateUserSelectedProjects' to reflect the update in the store as well.
   */

  const loadUserSelectedProjects = (projects) => {
    const allProjects = [...userSelectedProjects, ...projects]
      .reduce((acc, obj) => {
        const id = obj.id;
        if (!acc.has(id)) {
          acc.set(id, obj);
        }
        return acc;
      }, new Map())
      .values();

    const allProjectsArr = Array.from(allProjects);
    dispatch(updateUserSelectedProjects(allProjectsArr));
  };

  useEffect(() => {
    if (projects) {
      let tempArr = Object.values(projects).filter((item) => {
          if (item.hasContent === true) {
              return item;
          } else return null;
      });
      setProjectsForDownload(tempArr);
    }
  }, [projects]);
  /**
   * @function
   * @memberof WaveStatus
   * @param {Number} id Id of project type that is clicked.
   * @param {Boolean} checked Boolean for if a project type is clicked or not.
   *
   * @description Updates the state variable 'projectList'. For the clicked project type it updates the corresponding item in the 'projectList' array and sets the value of its 'selected' key to the 'checked' value received by the function. After updating the state variable it dispatches 'updateUserSelectedProjects' to update 'app.userSelectedProjects' in the store.
   */
  const handleCheck = (id, checked) => {
    setProjectList((prevList) => {
      const updatedList = _.cloneDeep(prevList);
      updatedList[updatedList.findIndex((x) => x.id === id)].selected = checked;
      dispatch(updateUserSelectedProjects(updatedList));
      return updatedList;
    });
  };

  /**
   * @function
   * @memberof WaveStatus
   * @param {Number} countryId Id of the country for which the new wave will be added.
   *
   * @description Navigates to the 'createWave' page corresponding to the country for which the 'Add Wave' button was clicked.
   */
  const onClickAddWave = (countryId) => {
    let countryObj = countries.filter(
      (country) => country.countryId === countryId
    )[0];
    navigate(`/waves/${countryObj.name}/createWave`);
  };

  /**
   * @function
   * @memberof WaveStatus
   * @param {String} country String corresponding to the country name that will be used when navigating to the 'ModuleSelection' page.
   * @param {Number} waveId Number corresponding to the id of the wave that will be used when navigating to the 'ModuleSelection' page.
   *
   * @description Navigates to the 'ModuleSelection' page using the country name and wave id corresponding to the wave for which the 'Module Selection' button was clicked.
   *
   * @returns {Number} waveId
   */
  const onClickModuleSelection = (country, waveId) => {
    const cntryObj = countries.filter(
      (cntry) => cntry.countryId === country
    )[0];

    const currentWave = cntryObj.waves.find((wave) => wave.waveId === waveId);

    const moduleSelectionProcess = currentWave.waveProcesses.find(
      (waveProcess) => waveProcess.process.name === 'Module selection'
    );

    if (
      moduleSelectionProcess &&
      moduleSelectionProcess.status === 'Finished'
    ) {
      if (!checkUXPrivileges(uxPrivileges, 'approveModule')) {
        setShowRequestSelectionUnlockModal({
          show: true,
          countryId: country,
          waveId: waveId,
          countryName: cntryObj.name,
        });
      } else {
        setShowModalSelectionFinishedModal(true);
        dispatch(GetWave(waveId));
        setCountryInfo({ country, waveId });
      }
    } else {
      navigate(`/waves/${cntryObj.name}/${waveId}`);
    }

    return waveId;
  };
  const handleRequestSelectionUnlockModalClose = () => {
    setShowRequestSelectionUnlockModal({
      show: false,
      countryId: -1,
      waveId: -1,
      countryName: "",
    });
  };
  const handleUnlockSelectionRequest = () => {
    const inputParams = {
        WaveLink: getURLOrigin() + /waves/ + `${showRequestSelectionUnlockModal.countryName}/${showRequestSelectionUnlockModal.waveId}`,
    };
    dispatch(
      sendNotification(
        notifications.unlockClientSelectionRequest,
        showRequestSelectionUnlockModal.countryId,
        showRequestSelectionUnlockModal.waveId,
        inputParams
      )
    );
    handleRequestSelectionUnlockModalClose();
  };
  /**
   * @function
   * @memberof WaveStatus
   * @param {String} country String corresponding to the country name that will be previewed.
   * @param {Number} waveId Number corresponding to the id of the wave that will be previewed.
   *
   * @description Navigates to the 'QuestionnairePreview' page using the country name and wave id corresponding to the wave for which the 'Preview' button was clicked.
   *
   * @returns {Number} waveId
   */
  const onClickModulePreview = (country, waveId) => {
    let cntryObj = countries.filter((cntry) => cntry.countryId === country)[0];
    navigate(`/preview/${cntryObj.name}/${waveId}`);
    return waveId;
  };

  /**
   * @function
   * @memberof WaveStatus
   * @param {Number} countryId Id of the country corrensponding to the country that contains the wave that will be edited.
   * @param {Number} waveId Id of the wave which will be edited.
   *
   * @description Navigates to the 'editWave' page corresponding to the wave for which the 'Edit wave details' button was clicked.
   *
   * @returns {Number} waveId
   */
  const onClickEditWave = (country, waveId) => {
    let cntryObj = countries.filter((cntry) => cntry.countryId === country)[0];
    navigate(`/waves/${cntryObj.name}/${waveId}/editWave`);
    return waveId;
  };

  const handleUnlockModuleSelection = (isfullModuleSelectionUnlock) => {
    let updatedWaveProcesses = _.cloneDeep(wave.waveProcesses);
    updatedWaveProcesses.map((process) => {
      if (process.process.name === 'Module selection') {
        process.status = 'In progress';
        }
        return process;
    });

    let temporaryWave = {
      ...wave,
      waveProcesses: updatedWaveProcesses,
    };
    if(isfullModuleSelectionUnlock){
      setClientSelectionUnlock(temporaryWave);
    }
    dispatch(UnlockWaveModuleSelection(temporaryWave, wave.waveId)); 
    dispatch(
      sendNotification(
        notifications.unlockedModuleSelection,
        wave.countryId,
        wave.waveId
      )
    );
    
    const countryName = countries.find(
      (country) => country.countryId === countryInfo.country
    ).name;

    dispatch(
      updateWaveCurrentStatus({
        id: 1,
        name: 'Module selection',
        status: 'In progress',
      })
    );
    navigate(`/waves/${countryName}/${countryInfo.waveId}`);   
  };
  const  setClientSelectionUnlock = (wave) => {
    wave.clientSelectionLocked = false
   };
  const moduleSelectionFinishedModalContent = (
    <div className={styles.modalContent}>
      <div className={styles.modalContentHeader}>
        <p>Module selection locked</p>
        <Button
          type={'close'}
          handleOnClick={() => setShowModalSelectionFinishedModal(false)}
        />
      </div>
      <div className={styles.modalContentMain}>
        <p>{messages?.moduleSelectionLocked?.text}</p>
      </div>
      <div className={styles.modalContentFooter}>
        <Button
          type="secondary"
          handleOnClick={() => setShowModalSelectionFinishedModal(false)}
        >
          Cancel
        </Button>
        <Button type="primary" handleOnClick={() => handleUnlockModuleSelection(false)}>
         Unlock Local questions
        </Button>
        <Button type="primary" handleOnClick={() => handleUnlockModuleSelection(true)}>
        Unlock full module selection
        </Button>
      </div>
    </div>
  );

  /**
   * @method useLayoutEffect
   * @memberof WaveStatus
   *
   * @description  When a country name is present in the URL params the country is scrolled to on the 'WaveSelection' page.
   * The effect is executed when the component is first mounted
   */
  useLayoutEffect(() => {
    // running on timeout if they navigate directly to the link. First time doenst work with regular useEffect.
    /* istanbul ignore next */ // untestable
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
      return () => {
        clearTimeout(timer);
    };
    }
  }, [scrollTo]);

  /**
@method useEffect - 1st instance  
   * @memberof WaveStatus
   *
   * @description When the component mounts it dispatches 'getProjectIds' to get the data for all projects available, and it updates 'app.projects' in the store.
   */
  useEffect(() => {
    if (!projects) {
      dispatch(getProjectIds());
    }
  }, []);

  /**
@method useEffect - 2nd instance  
   * @memberof WaveStatus
   * @param {Object} projects Object containing all project types, each one containing the keys 'isActive', 'name' and 'projectId'.
   *
   * @description When the component mounts, and if the 'projects' variable is not falsy the effect maps over the 'projects' object, creating an array of object from it, each object containing a project's 'id', 'name', and 'selected' properties. After doing so the function 'loadUserSelectedProjects' is called, with the array that was created as its argument.
   */
  useEffect(() => {
    if (projects) {
      const arr = Object.keys(projects).map((k) => ({
        id: projects[k].projectId,
        name: projects[k].name,
        selected: projects[k].isActive,
      }));
      loadUserSelectedProjects(arr);
    }
  }, [projects]);

  /**
@method useEffect - 3rd instance  
   * @memberof WaveStatus
   * @param {Object} userSelectedProjects
   *
   * @description When the 'userSelectedProjects' variable (taken from the store) is updated the effect updates the 'projectList' state variable with the value of 'userSelectedProjects'.
   */
  useEffect(() => {
    setProjectList(userSelectedProjects);
  }, [userSelectedProjects]);

  /**
@method useEffect - 4th instance
   *
   * @description  When a country is clicked on in the 'CountryMenu' component it scrolls the country into view on the 'WaveSelection' page
   */
  useEffect(() => {
    // if the waveId equals the currentWaveId expand the details and set ref to the ref of the currentWave
    /* istanbul ignore next */ // untestable
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 50); // without the timeout the scrolling behavior will not be triggered; this is the
      // exepcted behavior when you have multiple scrollIntoView methods called at the same time
      return () => {
        clearTimeout(timer);
    };
    }
  }, [scrollTo]);

  const handleShowDownloadModal = (country) => {
    setShowDownloadModulesModal(true);
    countryForDownload.current = country;
  };

    
  const handleGetProjectModules = (projectId) => {
    // setDownloadError({}) //add this in case we want to remove the error message when another project is selected (doesn't verify if there are any modules in the project)
    setProjectForDownload(projectId);
  };

  const handleProjectModulesDownload = async () => {
    const projName = projects[projectForDownload].name;
    console.log(
      projects[projectForDownload],
      projectModulesForDownload,
      Object.keys(projectModulesForDownload).length > 0,
      'tracking project ,,,,,,,,,,,,,'
    );
    if (
      (projectModulesForDownload &&
        Object.keys(projectModulesForDownload).length > 0) ||
      projects[projectForDownload].hasContent
    ) {
      navigate(
        `/preview/${countryForDownload.current.name}/${projectForDownload}/all-modules`
      );
      setDownloadError({});
      setShowDownloadModulesModal(false);
      countryForDownload.current = null;
    } else {
      setDownloadError({
        projectId: projectForDownload,
        countryId: countryForDownload.current.countryId,
        error: `No modules available for project ${projName}. Please select a different project or close this window.`,
      });
    }
    setProjectForDownload(null);
  };

  const downloadModulesModalContent = (
    <>
      {
        <div className={styles.modalContent}>
          <div className={styles.modalContentHeader}>
            <p>Modules download</p>
            <Button
              type={'close'}
              handleOnClick={() => setShowDownloadModulesModal(false)}
            />
          </div>
          <div className={styles.modalContentMain}>
            {downloadError.error ? (
              <p>{downloadError.error}</p>
            ) : (
              <>
                <p>
                  Please select a project, for which to download all available
                  modules:
                </p>
              </>
            )}
            <Dropdown
              // className={styles.dropdown}
              id={'projectList'}
              label={''}
              options={
                projectsForDownload.length > 0
                  ? projectsForDownload.map((item) => ({
                      label: item?.name,
                      value: item?.projectId,
                    }))
                  : null
              }
              placeholder={'Select project'}
              value={projectForDownload}
              onChange={(e) => handleGetProjectModules(e.target.value)}
            />
          </div>
          <div className={styles.modalContentFooter}>
            <Button
              type="secondary"
              handleOnClick={() => setShowDownloadModulesModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              handleOnClick={handleProjectModulesDownload}
              disabled={!projectForDownload}
            >
              Preview and download
            </Button>
          </div>
        </div>
      }
    </>
  );

  return (
    <div className={styles.container}>
      {showRequestSelectionUnlockModal.show && (
        <ModalDialog
          show={true}
          content={
            <RequestModuleSelectionUnlock
              id="id-RequestModuleSelectionUnlockDialog"
              handleConfirm={handleUnlockSelectionRequest}
              handleClose={handleRequestSelectionUnlockModalClose}
            />
          }
        />
      )}
      {showModalSelectionFinishedModal && (
        <div className={styles.modalContainer}>
          <ModalDialog
            show={true}
            content={moduleSelectionFinishedModalContent}
          />
        </div>
      )}
      {showDownloadModulesModal && (
        <div className={styles.modalContainer}>
          <ModalDialog show={true} content={downloadModulesModalContent} />
        </div>
      )}
      <div className={styles.ProjectFilter}>
        <MultiSelectDropdown
          dropdownTitle="Filter by project & year"
          content={projectList}
          handleCheck={handleCheck}
          additionalStyling={['ExtraBottomPadding', 'NoMargin']}
        ></MultiSelectDropdown>
      </div>
      {countries &&
        countries[0] &&
        countries.map((country) => {
          return (
            <div
              key={country.countryId}
              id={`country${country.countryId}`}
              ref={(el) => (ref.current[country.countryId] = el)}
            >
              <div className={styles.CountryHeader}>
                <h4 className={styles.heading}>{country.name}</h4>
                <Button
                  type="secondary"
                  handleOnClick={() => handleShowDownloadModal(country)}
                >
                  Download all modules
                </Button>
              </div>
              <AccordionDisplay
                filteredProjectList={projectList
                  .filter((x) => x.selected)
                  .map((y) => y.id)}
                country={country.countryId}
                countryName={country.name}
                items={country.waves}
                currentWaveId={currentWaveId}
                editWavesPrivilege={editWavesPrivilege}
                onClickEditWave={onClickEditWave}
                onClickModuleSelection={onClickModuleSelection}
                statusToStatusType={statusToStatusType}
                onClickModulePreview={onClickModulePreview}
                setShowModalSelectionFinishedModal={
                  setShowModalSelectionFinishedModal
                }
                showModalSelectionFinishedModal={
                  showModalSelectionFinishedModal
                }
                setCountryInfo={setCountryInfo}
              ></AccordionDisplay>
              {editWavesPrivilege ? (
                <footer className={styles.footer}>
                  <Button
                    type="bold"
                    id={country.countryId}
                    handleOnClick={onClickAddWave}
                  >
                    Add Wave
                  </Button>
                </footer>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default WaveStatus;
