import { cloneDeep as _cloneDeep, isArray } from 'lodash';
import { toastNotification } from '../components/ui/toastNotification/ToastNotification';
/**
 * @function
 * @memberof App
 * @param {Object} oldObject - The original object to be updated.
 * @param {Object} updatedProperties - An object containing the new properties to be merged into the old object.
 *
 * @description This function updates an object by merging new properties into it. It works as follows:
 *  1. It creates a deep clone of the 'oldObject' to avoid mutating the original object.
 *  2. It creates a deep clone of 'updatedProperties' to ensure that the updates are isolated and do not affect the original source.
 *  3. It merges these cloned objects, with the properties from 'updatedProperties' overwriting or adding to those in 'oldObject'.
 *  4. The function returns a new object that combines properties from both the old object and the updated properties.
 *
 * @returns {Object} A new object that is a combination of the old object and the updated properties.
 */
export const updateObject = (oldObject, updatedPorperties) => {
  const old = _cloneDeep(oldObject);
  const upd = _cloneDeep(updatedPorperties);
  return {
    ...old,
    ...upd,
  };
};
export const sortCountriesOrder = (countries) => {
  return countries.sort((a, b) => {
    return a.name - b.name;
  });
};

export const customQuestionloiContributionInMinutes = 0.3;

/**
 * @function
 * @memberof App
 * @param {Array} modList - An array of objects containing 'sequence' and 'surveyModuleId' properties.
 *
 * @description This function processes an array of module objects to create an ordered list of module IDs. It works as follows:
 *  1. It sorts the array based on the 'sequence' property of each object, ensuring the modules are in their correct order.
 *  2. it maps over the sorted array, extracting the 'surveyModuleId' from each object.
 *  3. The function returns a new array consisting only of 'surveyModuleId's, ordered according to their sequence.
 *
 * @returns {Array} An array of 'surveyModuleId's in the order specified by their sequence numbers.
 */
const buildOrederedList = (modList) => {
    return sortedModList(modList)    
    .map((x) => x.surveyModuleId);
};

/**
 * @function
 * @memberof App
 * @param {Array} modList - An array of module objects, each with 'sequence', 'groupModules', and 'surveyModuleId' properties.
 * @param {Object} newObj - An object containing module selection status and additional properties.
 *
 * @description This function builds a list of module groups based on a provided list of modules. It operates as follows:
 *  1. The function first sorts the 'modList' array based on the 'sequence' property of each module.
 *  2. It then iterates through each sorted module, focusing on those with non-empty 'groupModules'.
 *  3. For each group module, it either adds the module's ID to an existing group in 'groupList' or creates a new group entry.
 *  4. The group's 'valid' status is determined based on the module's selection status and specific group rules (like 'mandatoryChoice' or country-specific rules).
 *  5. The function returns 'groupList', an object where each key is a groupId and each value is an object detailing the group's properties and validity status.
 *
 * @returns {Object} An object with group IDs as keys, each mapping to an object containing the group's details and validity status.
 */

const sortedModList = (modList) => { return modList.sort((a, b) => a.sequence - b.sequence) };

const buildModuleGroupList = (modList, newObj) => {
    const groupList = {};
    const sortedModules = sortedModList(modList);

     sortedModules.forEach((module) => {
        if (module.groupModules.length > 0) {
            module.groupModules.forEach((group) => {
                const groupId = group.groupId;
                const existingGroup = groupList[groupId];

                if (existingGroup) {
                    existingGroup.moduleIds.push(module.surveyModuleId);
                    if(isValidGroup(existingGroup, group, newObj.moduleList[module.surveyModuleId].selected)){
                      existingGroup.valid = true;
                    }
                  } else {
                    groupList[groupId] = createGroup(groupId, group, module.surveyModuleId);
                    groupList[groupId].valid = getValidStatus(groupList[groupId], group, newObj.moduleList[module.surveyModuleId].selected);
                }
            });
        }
    });

  return groupList;
};

const isValidGroup = (group, groupRule, selectedModule) => {
    return isNotMandatoryGroupThisWave(groupRule) || (checkMandatoryChoiceGroup(group) && selectedModule);
};

const createGroup = (groupId, groupRule, moduleId) => {
    return {
        groupId,
        rule: groupRule.group.rule.name,
        moduleIds: [moduleId],
    };
};

const getValidStatus = (group,groupRule, selectedModule) => {
    if (isNotMandatoryGroupThisWave(groupRule)) {
        return true;
    } else if (checkMandatoryChoiceGroup(group)) {
        return selectedModule;
    }
    return false;
};

/**
 * @function
 * @memberof App
 * @param {Object} group - Object including properties for country-specific rules and mandatory settings.
 *
 * @description This function checks whether a group has country-specific rules and is not subject to 'mandatoryOncePerYear' or 'mandatoryFirstWave' constraints. It operates as follows:
 *  1. It first checks if the 'group' object exists and has the 'countryGroupRules' property with a non-empty array.
 *  2. It verifies that the group is neither mandatory once per year ('mandatoryOncePerYear') nor mandatory for the first wave ('mandatoryFirstWave').
 *  3. The function returns true if all these conditions are met, indicating the group has country-specific rules without the specified mandatory constraints.
 *  4. If any condition is not met, it returns false.
 *
 * @returns {Boolean} True if the group has country-specific rules and is not constrained by 'mandatoryOncePerYear' or 'mandatoryFirstWave', otherwise false.
 */
const isNotMandatoryGroupThisWave = (group) => {
  return (
    isMandatoryChoiceSingleWaveGroupRule(group?.group?.rule?.name) &&
    !group?.group?.mandatoryOncePerYear &&
    !group?.group?.mandatoryFirstWave
  );
};

/**
 * @function
 * @memberof App
 * @param {Boolean} isBoosterWave - Indicates if the current wave is a booster wave.
 * @param {Array} modList - An array of module objects, each containing properties 'surveyModuleId' and 'mandatory'.
 * @param {Array|String} selectedList - An array of selected modules or an empty string if no modules are selected.
 *
 * @description This function constructs the state of modules based on their selection and mandatory status. It operates as follows:
 *  1. It initializes an empty object 'newObj' to store the module states.
 *  2. If 'selectedList' is an empty string, 'newObj.moduleList' is built by setting each module's 'selected' and 'mandatory' status based on the 'isBoosterWave' flag and the module's 'mandatory' property.
 *  3. If 'selectedList' is not empty, it first sets all modules as unselected, then updates the 'selected' status of modules in 'selectedList'.
 *  4. The function also constructs 'newObj.groupList' using the 'buildModuleGroupList' function and 'newObj.orderedList' using the 'buildOrederedList' function.
 *  5. The function returns 'newObj', an object representing the current state of all modules and module groups.
 *
 * @returns {Object} An object representing the state of all modules and module groups, including selection and mandatory status.
 */
export const buildModuleState = (isBoosterWave, modList, selectedList) => {
  const newObj = {};
  if (selectedList === '') {
    newObj.moduleList = modList.reduce(
      (obj, item) => {
        obj[item.surveyModuleId] = {
          ..._cloneDeep(item),
          selected: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
          mandatory: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
        };
        return obj
      },
      {}
    );
  } else {
    newObj.moduleList = modList.reduce(
      (obj, item) => {
        obj[item.surveyModuleId] = {
          ..._cloneDeep(item),
          selected: false,
          mandatory: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
        };
        return obj
      },
      {}
    );
    selectedList.forEach(
      (element) => (newObj.moduleList[element.surveyModuleId].selected = true)
    );
  }
  newObj.groupList = buildModuleGroupList(modList, newObj);
  newObj.orderedList = buildOrederedList(modList);
  return newObj;
};
/**
 * @function
 * @memberof App
 * @param {Boolean} isBoosterWave - Indicates if the current wave is a booster wave.
 * @param {Boolean} isModuleMandatory - Indicates if a module is mandatory outside of booster waves.
 *
 * @description This function determines the mandatory status of a module within a booster wave. It operates as follows:
 *  1. If the current wave is a booster wave ('isBoosterWave' is true), the function returns false ( this means that no module is mandatory ).
 *  2. If it's not a booster wave, it returns the actual mandatory status of the module ('isModuleMandatory').
 * This function overrides the mandatory status of modules in booster waves, marking them as non-mandatory, while preserving their status in regular waves.
 *
 * @returns {Boolean} The mandatory status of the module taking into consideration if the wave is a booster or not.
 */
export const checkBoosterWaveNoMandatoryRule = (
  isBoosterWave,
  isModuleMandatory
) => {
  return isBoosterWave ? false : isModuleMandatory;
};

/**
 * @function
 * @memberof App
 * @param {Object} wave - Object representing a waveon which the function is called.
 *
 * @description Determines if a wave is a booster wave. The function returns true if the 'wave' object has a truthy 'booster' property, otherwise false.
 *
 * @returns {Boolean} True if the wave is a booster wave, otherwise false.
 */
export const isBoosterWave = (wave) => {
  return wave?.booster ? true : false;
};

/**
 * @function
 * @memberof App
 * @param {Object} modList - An object where the keys are moduleIds, and the values are the modules.
 *
 * @description This function constructs an array of objects representing the selected state of each module. It operates as follows:
 *  1. It first converts 'modList' into an array of its values.
 *  2. For each module object, it extracts 'surveyModuleId' and 'selected' properties.
 *  3.  Each module is represented as a new object with 'surveyModuleId' and 'isSelected' properties.
 *  4. The function returns an array of these new objects, providing a structured representation of modules and their selected states.
 *
 * @returns {Array} An array of objects, each containing 'surveyModuleId' and 'isSelected' properties for each module.
 */
export const buildSelectedModulePayload = (modList) => {
  const newArr = Object.values(modList).map((obj) =>
    (({ surveyModuleId, selected }) => ({
      surveyModuleId,
      isSelected: selected,
    }))(obj)
  );
  return newArr;
};

/**
 * @function
 * @memberof App
 * @param {Object|null} question - The original custom question object or null if creating a new question.
 * @param {Object} propsObj - An object containing properties to be updated or added to the question.
 * @param {Number|null} waveid - The wave ID, if applicable.
 * @param {Array|null} waveLanguages - Array of language objects for the wave.
 *
 * @description This function updates or creates a custom question object. It operates as follows:
 *  1. If 'question' is null, indicating a new question, it initializes 'newObj' with default values and generates text fields for each language in 'waveLanguages'.
 *  2. If 'question' is not null, it clones the existing question object into 'newObj'.
 *  3. The function iterates over 'propsObj', updating or adding properties to 'newObj', its 'question', 'customQuestionStatus', and 'questionTextFields' sub-objects.
 *  4. For language-specific updates, it checks each property in 'propsObj' for a 'languageId'. If found, the function updates or adds the corresponding language text field in 'newObj.question.questionTextFields'.
 *  5.The function returns 'newObj', an updated or newly created custom question object with the specified properties.
 *
 * @returns {Object} An updated or newly created custom question object.
 */

export const updateCustomQuestion = (
    question,
    propsObj,
    waveid = null,
    waveLanguages = null,
    dpInstructions = null
) => {
    const initialNewObj = getInitialNewObj(question, waveid, waveLanguages, dpInstructions);
    const updatedNewObj = updateNewObjWithProps(initialNewObj, propsObj);
    return updatedNewObj;
};

const getInitialNewObj = (question, waveid, waveLanguages, dpInstructions) => {
    if (question === null) {
        return getNewObjForNewQuestion(waveLanguages, dpInstructions, waveid);
    } else {
        return _cloneDeep(question);
    }
};

const getNewObjForNewQuestion = (waveLanguages, dpInstructions, waveid) => {
    const newTextFields = waveLanguages.map((language) => ({
        questionTextFieldId: 0,
        questionText: '',
        answerText: '',
        isTranslationApproved: false,
        questionId: 0,
        languageId: language.languageId,
    }));
    const newdpInstructions = dpInstructions?.map((dpInstructionData) => ({
        dpInstructionsId: 0,
        tableName: dpInstructionData.tableName,
        tableBase: dpInstructionData.tableBase,
        tableTitle: dpInstructionData.tableTitle,
        dpInstruction: dpInstructionData.dpInstruction,
        dpAdditionalInformation: dpInstructionData.dpAdditionalInformation,
        customQuestionId: dpInstructionData.customQuestionId,
    }));

    return {
        customQuestionId: 0,
        customNamePrefix: '',
        loiContributionInMinutes: customQuestionloiContributionInMinutes,
        isLegalApproved: false,
        requestDescription: '',
        scriptingInstruction: '',
        dpInstruction: '',
        waveId: waveid,
        customQuestionStatus: {
            status: 'New',
        },
        questionId: 0,
        question: {
            questionId: 0,
            name: '',
            surveyModuleId: null,
            questionTextFields: newTextFields,
        },
        comments: [],
        dpInstructions: newdpInstructions ?? [],
        questionPosition:''
    };
};

const updateNewObjWithProps = (newObj, propsObj) => {
    Object.keys(propsObj).forEach((key, ind) => {
        if (newObj.hasOwnProperty(key)) {
            newObj[key] = propsObj[key];
        }

        if (newObj.question.hasOwnProperty(key)) {
            newObj.question[key] = propsObj[key];
        }
        if (newObj.customQuestionStatus.hasOwnProperty(key)) {
            newObj.customQuestionStatus[key] = propsObj[key];
        }
        if (newObj.customNamePrefix.hasOwnProperty(key)) {
            newObj.customNamePrefix[key] = propsObj[key];
        }
        if (newObj.questionPosition.hasOwnProperty(key)) {
            newObj.questionPosition[key] = propsObj[key];
        }
        if (propsObj[key].hasOwnProperty('languageId')) {
            const index = newObj.question.questionTextFields.findIndex(
                (val) => val.languageId === propsObj[key]['languageId']
            );
            if (index !== -1) {
                newObj.question.questionTextFields[index] = updateObject(
                    newObj.question.questionTextFields[index],
                    propsObj[key]
                );
            } else {
                newObj.question.questionTextFields.push({ ...propsObj[key] });
            }
        }
        if (key === 'comment') {
            newObj.comments.push(propsObj.comment);
        }
    });
    return newObj;
};

/**
 * @function
 * @memberof App
 * @param {Object} data - An object containing key-value paris where each key is a module's 'surveyModuleId' and the value is the module object.
 * @param {String|null} sortKey - The key used for sorting the data, if applicable.
 * @param {String|null} filterKey - The key used for filtering the data, if applicable. Expected value is 'selected'.
 *
 * @description This function processes data for preview by sorting and filtering based on provided keys. It operates as follows:
 *  1. It first converts the 'data' object into an array. If 'data' is empty, it initializes 'previewData' as an empty array.
 *  2. If a 'sortKey' is provided, 'previewData' is sorted based on the values of 'sortKey', which is sequence, in ascending order. Thus the modules are sorted based on their 'sequence' value.
 *  3. If a 'filterKey' is provided, 'previewData' is filtered based on the value of 'filterKey', which is 'selected'. Thus the modules that are not 'selected' are sorted out.
 *  4. The function returns 'previewData', an array of data objects that have been optionally sorted and filtered.
 *
 * @returns {Array} An array of data objects, sorted and filtered based on the provided keys.
 */
export const GetPreviewData = (data, sortKey, filterKey) => {
  if (data !== undefined) {
    let previewData = Object.values(data).length ? Object.values(data) : [];
    if (previewData && previewData.length) {
      if (sortKey) {
        previewData = previewData.sort((a, b) => a[sortKey] - b[sortKey]);
      }
      if (filterKey) {
        previewData = previewData.filter((item) => item[filterKey] === true);
      }
    }

    return previewData;
  }
};

/**
 * @function
 * @memberof App
 * @param {String} text - The text to append to the base title.
 *
 * @description This function updates the document's title by appending the provided text to a base title. It sets the document's title to a formatted string combining a base title ('GfK Author') and the provided 'text'.
 */
export const updateTitle = (text) => {
  document.title = `GfK Author${text}`;
};

/**
 * @function
 * @memberof App
 * @param {Array} uxPrivileges - An array of privilege objects, each containing a 'name' property.
 * @param {String} privilege - A key representing a specific privilege to check for.
 *
 * @description This function checks if a specific user privilege exists in 'uxPrivileges'. It operates as follows:
 *  1. Based on the provided 'privilege' key, it assigns a corresponding privilege name to the variable 'name'.
 *  2. It then filters the 'uxPrivileges' array for an object whose 'name' property matches the assigned privilege name, considering case insensitivity.
 *  3. The function returns true if there is at least one matching privilege in the 'uxPrivileges' array, indicating the user has the specified privilege.
 *
 * @returns {Boolean} True if the specified privilege is found in the 'uxPrivileges' array, otherwise false.
 */
export const checkUXPrivileges = (uxPrivileges, privilege) => {
  let name = '';

  switch (privilege) {
    case 'editSelection':
      name = 'Edit module selection';
      break;
    case 'commentSelection':
      name = 'Comment module selection';
      break;
    case 'submitCustomQ':
      name = 'Submit custom question';
      break;
    case 'approveCustomQ':
      name = 'Approve custom question';
      break;
    case 'commentCustomQ':
      name = 'Comment on custom question';
      break;
    case 'editWave':
      name = 'Edit wave';
      break;
    case 'approveModule':
      name = 'Approve module selection';
      break;
    case 'buildCustomQ':
      name = 'Build custom question';
      break;
    case 'readOnly':
      name = 'Read Only';
      break;
    case 'OverwriteLoiLimit':
      name = 'OverwriteLoiLimit';
      break;
    case 'submitModuleSelection':
      name = 'Submit module selection';
      break;
    case 'DownloadOpsFile':
        name = 'Download OPS file';
        break;
    default:
      name = 'default';
  }

  const filtered = uxPrivileges.filter((p) => {
    return p.name.toLowerCase() === name.toLowerCase();
  });

  return filtered.length > 0;
};

/**
 * @function
 * @memberof App
 * @param {String} notificationName - The full name of a notification.
 *
 * @description This function maps full notification names to their corresponding short names. It operates as follows:
 *  1. Based on the provided 'notificationName', it uses a switch statement to determine the corresponding short name.
 *  2. Each case in the switch statement represents a different type of notification, mapping it to a concise identifier (short name).
 *  3. If the 'notificationName' does not match any predefined cases, 'shortName' is set to an empty string.
 *
 * @returns {String} The 'shortname' variable corresponding to the provided notification name, or an empty string if no match is found.
 */
export const notificationToShortName = (notificationName) => {
  let shortName;

  switch (notificationName) {
    case 'Module & local questions selections are due to be made':
      shortName = 'makeSelection';
      break;
    case 'Module choices and local question selections have been made and are awaiting approval (GfK) approval':
      shortName = 'approveSelection';
      break;
    case 'JTI make a query/request support regarding local questions':
      shortName = 'supportCustomQ';
      break;
    case 'GfK approve local questions':
      shortName = 'approvedCustomQ';
      break;
    case 'JTI requests contact/support re ad hoc research':
      shortName = 'supportAdHoc';
      break;
    case 'Reports are ready to review':
      shortName = 'reviewReports';
      break;
    case 'Testlink is ready to review':
      shortName = 'reviewTest';
      break;
    case 'GfK rejected module selection and local questions':
      shortName = 'rejectSelection';
      break;
    case 'JTI requested unlock client module selection':
      shortName = 'unlockClientSelectionRequest';
      break;
    case 'GfK unlocked module selection':
      shortName = 'unlockedModuleSelection';
      break;
    default:
      shortName = '';
  }

  return shortName;
};

/**
 * @function
 * @memberof App
 * @param {String} dateString - A date string to be formatted.
 *
 * @description This function converts a date string into a more readable format. It operates as follows:
 *  1. It first creates a Date object from the provided 'dateString'.
 *  2. It then extracts the year and the month from this Date object.
 *  3. The function uses an array of month names to convert the numeric month value to its corresponding name.
 *  4. It finally combines the month name and year into a single formatted string in the format 'Month Year'.
 *  5. If the month or year is not valid, it returns an empty string.
 *
 * @returns {String} A formatted date string in the 'Month Year' format, or an empty string if the input is invalid.
 */
export const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  return month && year ? `${month} ${year}` : '';
};

/**
 * @function
 * @memberof App
 * @param {Object} wave - An object representing a wave, which may include 'waveProcesses'.
 *
 * @description This function retrieves the start date of the 'Fieldwork' process from a wave object. It operates as follows:
 *  1. It first checks if the 'wave' object has a 'waveProcesses' property, which is an array of process objects.
 *  2. Then, it searches for a process object where the 'name' of the process is 'Fieldwork'.
 *  3. If such a process object is found, the function returns its 'startDate'.
 *  4. If no 'Fieldwork' process is found or the 'wave' object does not have the required property, it returns an empty string.
 *
 * @returns {String} The start date of the 'Fieldwork' process if found, otherwise an empty string.
 */
export const getFieldWorkStartDate = (wave) => {
  let waveFieldworkObj = wave?.waveProcesses?.find(
    (waveProcess) => waveProcess?.process?.name === 'Fieldwork'
  );
  return waveFieldworkObj ? waveFieldworkObj.startDate : '';
};

/**
 * @function
 * @memberof App
 * @param {Object} wave - An object representing a wave, which includes a 'name' property, and other wave'related properties.
 *
 * @description This function creates a formatted string combining a wave's name with its start date. It operates as follows:
 *  1. It first retrieves the wave's name from the 'wave' object.
 *  2. The function then calls 'getFieldWorkStartDate' to obtain the start date of the wave's 'Fieldwork' process.
 *  3. This date is formatted using 'getFormattedDate' to convert it into a more readable format.
 *  4. The formatted date is concatenated with the wave's name, separated by a '|'.
 *  5. If the 'wave' object has a valid name, it returns this concatenated string; otherwise, it returns an empty string.
 *
 * @returns {String} A string combining the wave's name and formatted start date, or an empty string if the wave's name is not provided.
 */
export const getWaveNameWithStartDate = (wave) => {
  const waveName = `${wave.name} | ${getFormattedDate(
    getFieldWorkStartDate(wave)
  )}`;
  return wave?.name ? waveName : '';
};

/**
 * @function
 * @memberof App
 * @param {String} answerText - A string that potentially contains JSON data.
 *
 * @description This function attempts to parse a string as JSON and determine its type. It operates as follows:
 *  1. If 'answerText' is empty or undefined, it immediately returns false.
 *  2. The function then attempts to parse 'answerText' using `JSON.parse`.
 *  3. If parsing is successful, it checks whether the parsed answer is an array or another type, and assigns this type to a variable.
 *  4. It returns an object with the parsed value and its determined type.
 *  5. If parsing fails (throws an error), it returns an object with the original answer text, its type (as a string), and an error flag set to true.
 *
 * @returns {Object} An object containing the parsed value, its type, and an error flag if parsing failed.
 */
export const parseAnswer = (answerText) => {
  if (!answerText) return false;

  try {
    const pAnswerText = JSON.parse(answerText);
    let type = '';
    if (isArray(pAnswerText)) {
      type = 'array';
    } else type = typeof pAnswerText;
    return { value: pAnswerText, type: type };
  } catch (error) {
    return { value: answerText, type: typeof answerText, error: true };
  }
};

/**
 * @function
 * @memberof App
 * @param {Object} wave - An object representing the wave.
 * @param {Object} groupData - An object containing data about a module group, including its mandatory status.
 * @param {Object} mandatoryChoiceGroup - An object specifying rules for mandatory choice within the group.
 *
 * @description This function determines whether a tooltip should be shown based on certain conditions related to a wave and module group. It operates as follows:
 *  1. It first checks if the current wave is not a booster wave using the 'isBoosterWave' function.
 *  2. If it's not a booster wave, it further checks if the group is marked as 'firstMandatory' and if it has any of the following conditions:
 *    - 'mandatoryGroupRuleOncePerYear' is true,
 *    - 'mandatoryOncePerYear' is true,
 *    - 'mandatoryChoiceGroup.rule' equals 'mandatoryChoice'.
 *  3. If any of these conditions are met, the function returns true, indicating that a tooltip should be shown.
 *  4. Otherwise, it returns false, indicating no tooltip is required.
 *
 * @returns {Boolean} True if the tooltip should be shown, otherwise false.
 */
export const shouldShowTooltip = (wave, groupData, mandatoryChoiceGroup) => {
  return (
    !isBoosterWave(wave) &&
    groupData.firstMandatory &&
    (groupData.mandatoryGroupRuleOncePerYear === true ||
      groupData.mandatoryOncePerYear === true ||
      checkMandatoryChoiceGroup(mandatoryChoiceGroup))
  );
};

/**
 * @function
 * @memberof App
 * @param {Object} groupData - Data object containing information about a module group's mandatory status.
 * @param {Object} mandatoryChoiceGroup - Object containing rules and validity status for a mandatory choice group.
 * @param {Boolean} savedModuleSelection - Indicates if a module selection has been saved.
 *
 * @description This function determines the type of icon to display based on the mandatory status of module groups and individual modules. It operates as follows:
 *  1. For groups with a 'mandatoryGroupRuleOncePerYear' or 'mandatoryGroupRuleFirstWave', it checks if the group is mandatory this wave and if the mandatory choice rule is valid. Based on these conditions, it returns 'Error', 'Ok', or 'Warning'.
 *  2. For individual modules with 'mandatoryOncePerYear', it returns 'Ok' if the module is mandatory this wave, otherwise 'Warning'.
 *  3. For mandatory choice groups, it evaluates the combination of 'savedModuleSelection' and the group's validity. Depending on these, it returns 'Error', 'Ok', or 'Warning'.
 *
 * @returns {'Error' | 'Ok' | 'Warning'} A string representing the type of icon to display, based on the mandatory status and rules.
 */

export const handleMandatoryIcon = (
    groupData,
    mandatoryChoiceGroup,
    mandatoryIconErr,
    savedModuleSelection
) => {
    if (
        groupData.mandatoryGroupRuleOncePerYear ||
        groupData.mandatoryGroupRuleFirstWave
    ) {
        return getMandatoryIconForGroupRule(
            groupData.mandatoryGroupThisWave,
            mandatoryChoiceGroup,
            mandatoryIconErr
        );
    } else if (groupData.mandatoryOncePerYear) {
        return getMandatoryIconForOncePerYear(
            groupData.mandatoryThisWave
        );
    } else if (checkMandatoryChoiceGroup(mandatoryChoiceGroup)) {
        return getMandatoryIconForChoiceGroup(
            mandatoryChoiceGroup,
            savedModuleSelection
        );
    }
    return null;
};

const getMandatoryIconForGroupRule = (
  isMandatoryGroupThisWave,
    mandatoryChoiceGroup,
    mandatoryIconErr
) => {
  if (isMandatoryGroupThisWave) {
    if (!mandatoryChoiceGroup.valid) {
       return mandatoryIconErr ? 'Error' : 'Warning';
     } else {
       return 'Ok';
     }
   } else {
     return 'Warning';
   }
};

const getMandatoryIconForOncePerYear = (isMandatoryThisWave) => {
  return isMandatoryThisWave ? 'Ok' : 'Warning';
};

const getMandatoryIconForChoiceGroup = (
    mandatoryChoiceGroup,
    savedModuleSelection
) => {
  if (!mandatoryChoiceGroup.valid) {
    return savedModuleSelection ? 'Error' : 'Warning';
  } else if (
    !isMandatoryChoiceSingleWaveGroupRule(mandatoryChoiceGroup.rule)
  ) {
    return 'Ok';
  }
};

/**
 * @function
 * @memberof App
 * @param {'Ok' | 'Warning' | 'Error'} a - A string representing a status.
 * @param {Boolean} b - A boolean flag that determines whether the border style should be set.
 *
 * @description This function determines the border color based on a given status and a boolean flag. It operates as follows:
 *  1. If the boolean flag 'b' is true, the function evaluates the status 'a'.
 *  2. Depending on the value of 'a', it returns a color:
 *    - 'Ok' results in 'Green',
 *    - 'Warning' results in 'Orange',
 *    - 'Error' results in 'Red'.
 *  3. If 'a' is none of these values or if 'b' is false, the function returns null.
 *
 * @returns {'Green'|'Orange'|'Red'|null'} A string representing the border color ('Green', 'Orange', 'Red'), or null if conditions are not met.
 */
export const setBorderStyle = (a, b) => {
  if (b) {
    switch (a) {
      case 'Ok':
        return 'Green';
      case 'Warning':
        return 'Orange';
      case 'Error':
        return 'Red';

      default:
        return null;
    }
  }
  return null;
};

/**
 * @function
 * @memberof App
 * @param {Object} styles - An object containing CSS module styles.
 * @param {Boolean} [active] - Indicates if the component is active, used for specific styling.
 * @param {Boolean} [isComponentVisible] - Indicates if the component is currently visible, used for dropdown styling.
 * @param {Array} [additionalStyling] - An array of additional styling keys to apply from the styles object.
 * @param {'checkbox' | 'dropdown'} [type] - A string indicating the type of component to apply corresponding base styles.
 *
 * @description This function dynamically generates a string of class names for a component based on its type and state. It operates as follows:
 *  * Based on the 'type' parameter, it selects a base style (either for checkbox or dropdown).
 *  * It then checks the 'active' and 'isComponentVisible' flags to apply additional conditional styles.
 *  * Finally, it iterates over the 'additionalStyling' array, appending any extra styles found in the 'styles' object.
 *
 * @returns {String} A string of class names concatenated together, providing the combined styles for the component.
 */


export const getStyling = (styles, extraStyles) => {
  let styleList = ``;
  if (extraStyles?.length > 0) {
    extraStyles.forEach((x) => {
      styleList += ` ${styles[x]}`;
    });
  }
  return styleList;
};

export const updateWaveProcess = (
  waveProcesses,
  updatedProcessName,
  updates
) => {
  return waveProcesses.map((proc) => {
    if (proc.process.name === updatedProcessName) {
      return {
        ...proc,
        ...(updates.process ? {} : updates),
        process: {
          ...proc.process,
          ...(updates.process || {}),
        },
      };
    }
    return proc;
  });
};

export const showToaster = (toast, autoClose) => {
  toastNotification({ title: toast.title, message: toast.message }, autoClose);
};

/**
 * @function
 * @param {String} status
 * @returns {string} a descriptive string corresponding to the status argument.
 */
export const getStatus = (status) => {
  switch (status) {
    case 'New':
      return 'New, undefined question.';
    case 'Requested':
      return 'Question definition or updates have been requested!';
    case 'Defined':
      return 'Question awaiting approval!';
    case 'Approved':
      return 'Question is approved!';
    default:
      return;
  }
};

export const getProjectCountries = (projects) => {
  let projectCountries = [];
  if (projects) {
    projectCountries = Object.values(projects).reduce(
      (projectCountries, project) => {
        if (project?.projectCountries?.length) {
          return projectCountries.concat(project.projectCountries);
        }
        return projectCountries;
      },
      []
    );
  }
  return projectCountries;
};
/**
 * @function
 * @param {Object} group
 * @returns {Boolean} returns true if group.rule value is same as mandatoryChoice or mandatoryChoiceOncePerYear or mandatoryChoice rule, else false.
 */
export const checkMandatoryChoiceGroup = (group) => {
  return (
    group?.rule === 'mandatoryChoiceOncePerYear' ||
    group?.rule === 'mandatoryChoiceFirstWave' ||
    group?.rule === 'mandatoryChoice'
  );
};
/**
 * @function
 * @param {Object} group
 * @returns {Boolean} returns true if ruleName value is same as  mandatoryChoiceOncePerYear or mandatoryChoiceFirstWave rule, else false.
 * @description All rule mentioned in this method are only required in one wave only.
 */
const isMandatoryChoiceSingleWaveGroupRule = (ruleName) => {
  return (
    ruleName === 'mandatoryChoiceOncePerYear' ||
    ruleName === 'mandatoryChoiceFirstWave'
  );
};
export const GetMessagesObject = (messageArray) => {
  return messageArray.reduce((messagesObj, message) => {
    messagesObj[message.messageKey] = message;
    return messagesObj;
  }, {});
};

export const getMessageWithReplacedKeyValuePairs = (
  message,
  replaceItems = []
) => {
  let updatedMessage = message;
  replaceItems.forEach((item) => {
    updatedMessage = updatedMessage?.replace(
      new RegExp(`{{${item.key}}}`, 'g'),
      item.value
    );
  });
  return updatedMessage;
};

export const getMessageWithReplacedKeyValue = (message, key, value) => {
  return message?.replace(new RegExp(`{{${key}}}`, 'g'), value);
};

export const GetFormattedLocalQuestionName = (
  customNamePrefix,
  customQuestionName
) => {
  return customNamePrefix + '_' + customQuestionName;
};
export const getWaveLink = () => {
  return window.location.href;
};

export const statusToStatusType = (status) => {
  switch (status) {
    case 'Finished':
      return 'success';
    case 'In progress':
      return 'progress';
    case 'Failed':
      return 'error';
    default:
      return 'default';
  }
};


export const parseEuDate = (dateStr, separator) => {
  const [day, month, year] = dateStr.split(separator).map(Number);
  return new Date(year, month - 1, day);
};

export const getURLOrigin = () => {
    const url = window.location.href;
    const origin = new URL(url).origin;
    return origin;
};